import { Flex} from "rebass";
import '../style/GameBoard.css';

const GameBoard = ({ boardState }) => {
    return (
        <Flex className="GameBoardContainer" >
          <div id="board" className="board">
              {boardState.map((row, i) => {
                return (
                  <row key={i} className='gameRow'>
                    {row.map((state, j) => {
                      return (
                        <div key={j} className="tile" data-state={boardState[i][j].state}>{boardState[i][j].letter}</div>
                      );
                    })}
                  </row>
                );
              })}
          </div>
        </Flex>
    )
}
export default GameBoard;