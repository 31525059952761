import React from "react";
import { Flex, Button, Box, Link} from "rebass";
import {
	Label,
	Input
} from '@rebass/forms'
import {ReactComponent as Logo} from '../resources/Title.svg';
import {ReactComponent as Modalimage} from '../resources/modalimage.svg';
import '../style/modal.css';

const Modal = ({ setInput, handleClick }) => {
    return (
        <Flex className="modal" >
            <Flex className="modal-content">
                <Flex className="modal-header">
                    <Logo className="Logo"/>
                    <Modalimage className="VGraphic"/>
                </Flex>
                    <Box width={1}>
                        <h4>
                            You've been invited to a Wardle game!
                        </h4>
                        <p>Wardle is a multiplayer game of wits and words
                            where you race against the clock to complete 
                            short <a sx={{color:'#F92020'}} href="https://www.powerlanguage.co.uk/wordle/">Wordle</a>  
                            &nbsp;challenges against your friends (or foes).
                            The quicker you complete each word the more points 
                            you get. Accumulate the most points across the rounds for
                            the ultimate wordle bragging rights!
                        </p>
                    </Box>
                    <Flex className="modal-footer">
                        <Label sx={{fontSize: '.8em', marginBottom: '.4em'}}>Enter your username to get started</Label>
                        <Input sx={{borderRadius: '4px', border: '1px solid #CED4DA', padding: '5px'}}
                            placeholder='Username'
                            onInput={e => setInput(e.target.value)}
                        />
                        <Button type="button" onClick={handleClick}  sx={{alignSelf: 'center', marginTop: '1.5em !important', color: 'white', backgroundColor: '#F16161', fontSize: '.8em'}} >Join Room</Button>
                        <Link sx={{alignSelf: 'center',  marginTop: '1em', borderBottom:'1px solid black', color: '#495057', fontWeight:'600', fontSize: '.8em'}}>
                            No Thanks
                        </Link>
                    </Flex>
            </Flex>
        </Flex>
    )
}
export default Modal;