import React from "react";
import { Flex } from "rebass";
import {
	Label
} from '@rebass/forms'
import {ReactComponent as Character} from '../resources/temp.svg';

const Avatar = ({username}) =>(
	<Flex flexDirection='column' sx={{
		inlineSize: '50px',
		margin: '.2em',
		flex: '1 0 21%',
		maxWidth:'5em'
	}}>
		<Character width='100%' height='75px' />
		<Label sx={{
			alignContent: 'center',
			overflowWrap: 'anywhere',
			justifyContent: 'center',
			textAlign: 'center',
			marginTop: '-.2em',
			fontSize: '.9em'

		}}> 
			{ username } </Label>
	</Flex>
);

export default Avatar;