import React, { useState, useEffect } from "react";
import { socket } from '../util/Socket';
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";
import { Flex } from "rebass";
import Keyboard from '../components/Keyboard/Keyboard.js';
import { KEY_STATE, KEY_CODES } from '../global/global.js';
import GameBoard from '../components/GameBoard.js';
import OPboard from '../components/OPboard.js';
import Navbar from '../components/Navbar.js';
import NameCard from '../components/NameCards';
import Avatar from '../components/Avatar.js';
import '../style/game.css';

function Game() {
    const [players, setPlayers] = useState([]);
    const [guess, setGuesses] = useState([]);
    const [selectedLetters, setSelectedLetters] = useState(Array(26).fill('none'));
    const [isPlaying, setIsPlaying] = useState('');
    const [gameComplete, setGameComplete] = useState(false);

    const [str, setStr] = useState('');

    var initialBoardState = Array.from(Array(6), () => 
        new Array(5).fill({state: 'empty', letter: ''}));
    const [boardState, setBoardState] = useState(initialBoardState);
    const [currRow, setCurrRow] = useState(0);
    const [currCol, setCurrCol] = useState(0);

    const cookies = new Cookies();

    const { roomId } = useParams();

    useEffect(() => {
        socket.emit('load game', { roomId : roomId, playerId: cookies.get('playerId') });

        socket.on('room players', loadPlayers);

        socket.on('guess result', handleGuessResult);

        socket.on('countdown', (time) => {
            var minutes = Math.floor(time / 60);
            var seconds = time - minutes * 60;
            var finalTime = str_pad_left(minutes, '0', 2)+':'+str_pad_left(seconds, '0', 2);
            document.getElementById("timer").innerHTML = finalTime;
        });

        return() => {
            socket.off();
        }
    }, [socket]);

    useEffect(() => {
        // WE NEED THIS BC SOCKET HANDLERS READ THE 
        // INITIAL STATES OF STATE VARIABLES
        // handleServerResponse();
    }, [guess]);

    function str_pad_left(string,pad,length) {
        return (new Array(length+1).join(pad)+string).slice(-length);
    }

    const handleGuessResult = data => {
        // console.log("RECEIVING BOARD");
        // console.log(data.boardState);
        // console.log("RECEIVING COL: " + data.currCol);
        console.log("SETTING PLAYERS");
        setPlayers(data.players);
        if(data.playerId === cookies.get('playerId')){
            setBoardState(data.boardState);
            setCurrRow(data.currRow);
            setCurrCol(data.currCol);
            setStr(data.str);
            setSelectedLetters(data.selectedLetters);
            setIsPlaying(data.isPlaying);
            setGuesses(guess => [...guess, data.guessResults]);
        }
    }

    const loadPlayers = data => {
        var gamePlayers = data.players;
        setPlayers(gamePlayers);
        const thisPlayer = gamePlayers => gamePlayers.playerId === cookies.get('playerId');
        const playerIndex = gamePlayers.findIndex(thisPlayer);
        setIsPlaying(gamePlayers[playerIndex].isPlaying);

        if(data.players.some(e => e.playerId === cookies.get('playerId'))){
            setBoardState(gamePlayers[playerIndex].boardState);
            setCurrRow(gamePlayers[playerIndex].currRow);
            setCurrCol(gamePlayers[playerIndex].currCol);
            setStr(gamePlayers[playerIndex].word);
            setSelectedLetters(gamePlayers[playerIndex].selectedLetters);
        }
    }

    const pressEnter = () => {
        if(str.length === 5 && isPlaying){
            console.log('SUBMITTING');
            socket.emit("player guess", { 
                roomId : roomId, 
                playerId : cookies.get('playerId'), 
                word : str.toLowerCase(), 
                boardState: boardState, 
                currRow: currRow,
                selectedLetters: selectedLetters
            });
        }
    };

    const pressBackspace = () => {
        if(str !== '' && !gameComplete){
            setStr(str.substring(0, str.length - 1));

            var newArr = [...boardState];
            newArr[currRow][currCol - 1] = {state: KEY_STATE.EMPTY, letter: ''};
            setBoardState(newArr);
            
            if(currCol - 1 >= 0){
                setCurrCol(prev => prev - 1);
            }
        }
    };

    const pressLetter = (value) => {
        if(str.length < 5){
            setStr(str + value);

            var newArr = [...boardState];
            newArr[currRow][currCol] = {state: KEY_STATE.TBD, letter: value};
            setBoardState(newArr);

            if(currCol + 1 < 6){
                setCurrCol(prev => prev + 1);
            }
        }
    };

    function physicalKeyboardHandler(event) {
        event.preventDefault();
        var keyCode = event.keyCode;
        var key = event.key;

        if(!(event.ctrlKey && key === 'r'))
        {
            if(isValidChar(keyCode, key)){
                pressLetter(key.toUpperCase());
            }
            else if(keyCode === KEY_CODES.BACKSPACE || keyCode === KEY_CODES.DELETE){
                pressBackspace();
            }
            else if(keyCode === KEY_CODES.ENTER){
                pressEnter();
            }
        }
        else {
            window.location.reload(false);
        }
    }

    const isValidChar = (keyCode, key) => {
      return keyCode <= KEY_CODES.Z_KEY &&
        keyCode >= KEY_CODES.A_KEY &&
        key.match(/^[aA-zZ]$/);
    }

    const startRound = () => {
        socket.emit('start timer', {roomId: roomId, playerId: cookies.get('playerId')});
    }

    return (  
        <div onKeyDown={(e) => physicalKeyboardHandler(e)} tabIndex='0'>  
            <Flex className="section game" >
               <Navbar
                id="gamenav" 
                />
                {/*<h1 id='timer'></h1>*/}
                <div className="OPBoardContainer" >
                    {players.map((player) => {
                        if(player.playerId !== cookies.get('playerId')){
                            return (
                                <OPboard key={player.playerId}
                                    name={player.username}
                                    boardState={player.boardState}
                                />  
                            )
                        }
                    })}
                </div>
                <Flex className='CardContainer'>
                    {players.map((player) => 
                        <NameCard
                            key={player.playerId}
                            Avatar={Avatar}
                            Border={{border: 'black solid 1px'}}
                            Username={player.username}
                            Points={player.currentScore}
                        />
                    )}
                </Flex>
                <GameBoard
                    boardState={boardState}
                />
                <Keyboard 
                    selectedLetters={selectedLetters}
                    pressVirtualKeyboard={pressLetter}
                    pressEnter={pressEnter}
                    pressBackspace={pressBackspace}
                />
            </Flex>          
        </div>
    );
}

export default Game;