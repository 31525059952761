import React, { useEffect, useState } from "react";
import { Flex, Button, Box } from "rebass";
import Settings from '../components/Settings.js';
import {
    Label
} from '@rebass/forms'
import '../style/lobby.css';
import Avatar from '../components/Avatar.js';
import { useParams } from "react-router-dom";
import { socket } from '../util/Socket';
import Cookies from "universal-cookie";
import Modal from '../components/Modal.js';
import Navbar from '../components/Navbar.js';

function Room() {
    const [input, setInput] = useState('');
    const [isRoom, setIsRoom] = useState('');
    const [isAdmin, setIsAdmin] = useState('');
    const [isPlayer, setIsPlayer] = useState('');
    const [players, setPlayers] = useState([]);
    const [modalDisabled, setModalDisabled] = useState('');
    const [settings, setSettings] = useState({rounds: 5, timeLimit: 60, maxPlayers: 4});

    const { roomId } = useParams();
    const cookies = new Cookies();

    useEffect(() => {
        socket.emit("check room", { roomId : roomId });

        socket.on("is room", handleRoomState);

        socket.on("is admin", handleAdminState);
    
        socket.on("is player", handlePlayerState);

        socket.on('room players', loadPlayers);

        socket.on("join room", handleJoinRoom); 

        socket.on("game start", handleGameStart);

        return() => {
            socket.off();
        }
    }, [socket]);

    useEffect(() => {
        console.log(settings);
    }, [settings]);

    const handleRoomState = data => {
        if (data.isRoom) {
            socket.emit("check admin", { roomId : roomId });
            socket.emit("check player", { playerId : cookies.get('playerId') , roomId : roomId });
        }
        setIsRoom(data.isRoom);
    }

    const handleAdminState = data => {
        if (data.admin.localeCompare(cookies.get('playerId')) === 0){
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }

    const handlePlayerState = data => {
        setIsPlayer(data.isPlayer);
    }

    const loadPlayers = data => {
        if(data.players.some(e => e.playerId === cookies.get('playerId'))){
            setModalDisabled(true);
        }
        setPlayers(data.players);
    }

    const handleClick = event => {
        event.preventDefault();
        setModalDisabled(true);
        socket.emit("player join room", { username : input , roomId : roomId });
    }

    const handleJoinRoom = data => {
        cookies.set('playerId', data.playerId, { path: '/'});
        setIsPlayer(true);
    };

    const handleStart = event => {
        event.preventDefault();
        socket.emit("start game", { roomId : roomId, rounds: settings.rounds, timeLimit: settings.timeLimit, maxPlayers: settings.maxPlayers });
    }

    const handleGameStart = data => {
        window.location.href=('/game/' + data.roomId);
    }

    const handleSettingsCallback = (settingsData) => {
        setSettings(settingsData);
    }

    if (isRoom) {
        return (
            <div>
                <Flex xs={{overflowX: 'hidden'}} className="section" id="lobby" >
                        <Navbar
                        id="gamenav" 
                        />
                    <div id="line"></div>
                    <Flex className="lobby-container">
                        <Flex className="lobby">
                            <Box className='lobby-box'>
                                <Flex className="settings-container">
                                    
                                { isAdmin ? 
                                    <Settings settingsCallback={handleSettingsCallback} roomId={ roomId } handleStart={ handleStart } buttonName="Start Game"/> : ''
                                }
                                </Flex>
                                <Flex className="right-side-lobby">
                                    <Label mb={3} sx={{
                                        justifyContent: 'center',
                                        fontWeight: '600',
                                        fontSize: '1.2em',
                                    }}> Players {players.length} </Label>
                                    <Flex className="avatar-container">
                                        { players.map((name,index) => 
                                            <Avatar key={index} username={name.username} height="75px" width="50px" />
                                        )}
                                    </Flex>
                                </Flex>
                            </Box>
                        </Flex>
                        { isAdmin ? 
                            <Button id="startbutton" type='submit' onClick={handleStart} className='button-primary' mt={3} backgroundColor='green'>{"Start Game"}</Button> : ''
                        }
                    </Flex>
                    { isAdmin ? '' : modalDisabled ? '' : <Modal setInput={setInput} handleClick={handleClick}/> }\
                </Flex>
            </div>
        )
    }
    else {
        return (
            <div>not room</div>
            )
    }
    

}

export default Room;