import "../../style/key.css";

const Key = ({ char, state, pressVirtualKeyboard, pressEnter, pressBackspace }) => {
  if (char === "ENTER") {
    return (
      <button
        id="button"
        type="button"
        data-key={char}
        onClick={pressEnter}
        className="one-and-a-half Enter"
      >
        {char}
      </button>
    );
  } else if (char === "DELETE") {
    return (
      <button
        id="button"
        type="button"
        data-key={char}
        onClick={pressBackspace}
        className="two Delete"
      >
        {char}
      </button>
    );
  } else {
    return (
      <button
        id="button"
        type="button"
        data-key={char}
        data-state={state}
        onClick={() => pressVirtualKeyboard(char)}
      >
        {char}
      </button>
    );
  }
};
export default Key;