import { Flex} from "rebass";
import '../style/OPboard.css';

const OPboard = ({name, boardState}) => {
    return (
        <div>
            <h4>{name}</h4>
            <div id="OPboard" className="OPboard">
                {boardState.map((row, i) => {
                    return (
                        <row key={i} className='OPRow'>
                            {row.map((state, j) => {
                              return (
                                <div key={j} className="tile" data-state={boardState[i][j].state}></div>
                              );
                            })}
                        </row>
                    )
                })}
            </div>
        </div>
    )
}
export default OPboard;