import React from "react";
import { Box, } from "rebass";
import {
	Label,
	Select,
	Input
} from '@rebass/forms'
import ReactTooltip from "react-tooltip";
import '../style/settings.css';

function copy() {
  var copyText = document.getElementById("link");
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  navigator.clipboard.writeText(copyText.value);
  
  var tooltip = document.getElementById("myTooltip");
  tooltip.innerHTML = "Copied!";
  
}

function outFunc() {
	var tooltip = document.getElementById("myTooltip");
	setTimeout(function(){
		tooltip.innerHTML = "Copy to clipboard"
	},500);
}


function Settings({roomId, settingsCallback}){
	const onTrigger = (event) => {
		event.preventDefault();
		var data = {
			rounds: document.getElementById('rounds').value, 
			timeLimit: parseInt(document.getElementById('timeLimit').value), 
			maxPlayers: document.getElementById('maxPlayers').value
		}
		settingsCallback(data);
	}
	return (
		<Box className='settings' mr={4}>
			<Label mb={3} color='#495057'
				sx={{fontWeight: '600',
				fontSize: '1.2em',}}>
				 	Lobby Settings
				</Label>
			<form>
				<Label mb={2} color='#495057'> Rounds </Label>
				<Select onChange={onTrigger} id="rounds" mb={3} sx={{ width:'100%', padding: '5px', borderRadius: '4px', backgroundColor:'white'}}>
					<option>5</option>
					<option>8</option>
					<option>10</option>
				</Select>
				<Label mb={2} marginTop='.5em' color='#495057'> Time Limit (Seconds) </Label>
				<Select onChange={onTrigger} id="timeLimit" mb={3} sx={{padding: '5px',  borderRadius: '4px', backgroundColor:'white'}}>
					<option>60</option>
					<option>120</option>
					<option>180</option>
				</Select>
				<Label mb={2} marginTop='.5em' color='#495057'> Maximum Players </Label>
				<Select onChange={onTrigger} id="maxPlayers" mb={3} sx={{padding: '5px', borderRadius: '4px', backgroundColor:'white'}}>
					<option>4</option>
					<option>6</option>
					<option>8</option>
				</Select>
				<Label 	 mb={2} marginTop='.5em' color='#495057'> Invite Link </Label>
					<Input 
					data-tip data-for="Tip"
					id='link'
					onClick={copy} 
					onMouseOut={outFunc} 
					sx={{padding: '5px', borderRadius: '4px', backgroundColor:'white'}} 
					readOnly 
					value={'https://word-battle.herokuapp.com/' + roomId}
					/>
					<ReactTooltip id="Tip" place="right" effect="solid">
						Click to copy to clipboard
					</ReactTooltip>
					<div id="myTooltip">Copy to clipboard</div>
			</form>
		</Box>
	);
};

export default Settings;