export const KEY_CODES = {
  ENTER: 13,
  BACKSPACE: 8,
  DELETE: 46,
  A_KEY: 65,
  Z_KEY: 90,
};

export const KEY_STATE = {
  POSSIBLE: "possible",
  INCORRECT: "incorrect",
  CORRECT: "correct",
  EMPTY: "empty",
  TBD: 'tbd'
};

export const CAMEL_LETTERS = [
  "Q",
  "W",
  "E",
  "R",
  "T",
  "Y",
  "U",
  "I",
  "O",
  "P",
  "A",
  "S",
  "D",
  "F",
  "G",
  "H",
  "J",
  "K",
  "L",
  "ENTER",
  "Z",
  "X",
  "C",
  "V",
  "B",
  "N",
  "M",
  "DELETE",
];