import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from './Home';
import Room from './Room';
import Game from './Game';


function App() {
  return (
      <Router>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/:roomId' element={<Room/>}/>
          <Route exact path='/game/:roomId' element={<Game/>}/>
        </Routes>
      </Router>
  );
}

export default App;
