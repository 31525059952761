import { Flex, Text, Box} from "rebass";
import {ReactComponent as Logo} from '../resources/logo.svg';
import {ReactComponent as Gears} from '../resources/gearIcon.svg';
import {ReactComponent as Question} from '../resources/questionIcon.svg';
import '../style/navbar.css';

const Navbar = ({}) => {
    return (
        <Flex
            id="nav"
            px={2}
            color='white'
            bg='rgba(219, 218, 218, .20)'
            alignItems='center'>
            <Logo id="logo" />
            <Box mx='auto' />
            <a><Gears id="navbuttons"/></a>
            <a><Question className="rightbutton" /></a>
            
        </Flex>
    )
}
export default Navbar;