import React, { useState, useEffect, useContext, useCallback, Component } from "react";
import Cookies from "universal-cookie";
import { socket } from '../util/Socket';
import {ReactComponent as Logo} from '../resources/logo.svg';
import {ReactComponent as Avatars} from '../resources/Icons.svg';
import { Flex, Button, Box } from "rebass";
import {
	Label,
	Input
} from '@rebass/forms'
import '../style/home.css';

function Home() {
  const [input, setInput] = useState('');
  const cookies = new Cookies();

	useEffect(() => {
		socket.on("join room", handleJoinRoom); 
		
		return() => {
			socket.off("join room", handleJoinRoom);
		}
	}, [socket]);

  const handleJoinRoom = data => {
    cookies.set('playerId', data.playerId, { path: '/'});
    window.location.href=('/' + data.roomId);
  };

  const handleClick = event => {
    event.preventDefault();
    cookies.set('username', input, { path: '/' });
    socket.emit("player generate room", {username : input});
  }

	return (
		<Flex className="section" id="home" >
			<Flex className="menu-container">
				<Flex className="menu">
					<Box p={50} className='menu-box'>
						<Logo style={{width: '100%'}}/>
						<h1 className='slogan'> a battle of wits and words </h1>
						<Avatars/>
						<Box
							as='form'
							onSubmit={e => e.preventDefault()}
							pt={4}
						>
							<Label mb={2} color='#495057'> Username </Label>
							<Input maxlength="15" placeholder='Enter your username' onInput={e => setInput(e.target.value)} className='input-field'/>
							<Button id="homebutton" className='button-primary' onClick={handleClick} mt={3} backgroundColor='black'>Create Room</Button>
						</Box>
					</Box>
				</Flex>
			</Flex>
		</Flex>
	);
}
export default Home;