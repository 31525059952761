import { CAMEL_LETTERS } from "../../global/global";
import Key from "../Key/Key.js";
import "../../style/keyboard.css";


const Keyboard = ({selectedLetters, pressVirtualKeyboard, pressEnter, pressBackspace}) => {
  return (
    <div id="keyboard">
      <div className="row">
        {CAMEL_LETTERS.slice(0, 10).map((key) => {
          return (
            <Key
              key={key}
              char={key}
              state={selectedLetters[key.toLowerCase().charCodeAt(0) - 'a'.charCodeAt(0)]}
              pressVirtualKeyboard={pressVirtualKeyboard}
            />
          );
        })}
      </div>
      <div className="row">
        <div className="spacer"></div>
        {CAMEL_LETTERS.slice(10, 19).map((key) => {
          return (
            <Key
              key={key}
              char={key}
              state={selectedLetters[key.toLowerCase().charCodeAt(0) - 'a'.charCodeAt(0)]}
              pressVirtualKeyboard={pressVirtualKeyboard}
            />
          );
        })}
         <div className="spacer"></div>
      </div>
      <div className="row">
        {CAMEL_LETTERS.slice(19, 29).map((key) => {
          return (
            <Key
              key={key}
              char={key}
              state={selectedLetters[key.toLowerCase().charCodeAt(0) - 'a'.charCodeAt(0)]}
              pressVirtualKeyboard={pressVirtualKeyboard}
              pressEnter={pressEnter}
              pressBackspace={pressBackspace}
            />
          );
        })}
      </div>
    </div>
  );
};
export default Keyboard;