import '../style/NameCard.css';


const NameCard = ({Username, Points, Avatar, Border}) => {
    return (
        <div style={Border} id="card" >
            <Avatar sx={{maxWidth: '5em'}}/>
            <div id="cardRight">
                <span id="Username">{Username}</span>
                <span id="Points">{Points}</span>
            </div>
        </div>
    )
}
export default NameCard;